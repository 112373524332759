import { makeStyles } from '@material-ui/core'
import type { DialogProps } from '@ui/paintscout'
import { Alert, Collapse, DropdownSelect, SingleDatePicker } from '@ui/paintscout'
import { Checkbox, FormControlLabel } from '@ui/paintscout'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputField,
  KeypadInputField,
  useClientOptions
} from '@ui/paintscout'
import { getFeatures, getPaymentMethodOptions, getPayments, setPayments, uuid } from '@paintscout/util/builder'
import moment from 'moment'
import type { Payment, QuoteDocument } from 'paintscout'
import React, { useState } from 'react'
import { useQuote } from '../../context/useQuote'

export interface PaymentsDialogProps extends DialogProps {
  paymentIndex?: number
  backOnCancel?: boolean
  markingPaid?: boolean
  onConfirm?: (quote: QuoteDocument) => void
  onCancel?: () => void
}

export interface PaymentsDialogState {
  existing: boolean
  payment: Payment
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: '1fr',
    gridGap: theme.spacing()
  },
  overflowVisible: {
    overflow: 'visible'
  }
}))

function PaymentsDialog(props: PaymentsDialogProps) {
  const { open, paymentIndex, markingPaid, onConfirm, onCancel, backOnCancel, ...baseDialogProps } = props
  const classes = useStyles(props)
  const { options } = useClientOptions()
  const { quote, updateQuote } = useQuote()
  const { paymentRequests: paymentRequestsFeature } = getFeatures({ options })

  const [validationError, setValidationError] = useState(false)
  const existingPayment = typeof paymentIndex !== 'undefined' && getPayments({ quote })[paymentIndex]
  const isRequest = !!existingPayment?.id && existingPayment.status === 'pending' && paymentRequestsFeature

  const [payment, setPayment] = useState<Payment>(
    existingPayment
      ? {
          ...existingPayment,
          ...(markingPaid
            ? {
                date: Date.now()
              }
            : {})
        }
      : {
          id: uuid(),
          type: null,
          amount: quote.totals.balance_due,
          date: Date.now(),
          note: '',
          deposit: false,
          manual: !isRequest,
          status: 'paid'
        }
  )

  const dateValue = moment(payment.date)

  const typeOptions = getPaymentMethodOptions(options).map((option) => ({
    ...option,
    value: option.label
  }))

  const typeValue = payment.type
    ? {
        key: payment.type,
        label: payment.type,
        value: payment.type
      }
    : null

  const leftButton = (
    <Button onClick={handleCancel} variant={'text'}>
      {backOnCancel ? '< Back' : 'Cancel'}
    </Button>
  )

  const defaultTitle = `${existingPayment ? (isRequest ? 'Edit' : 'View') : 'Add'} Payment${isRequest ? ' Request' : ''}`
  const readonlyFields = existingPayment && !isRequest && !markingPaid

  return (
    <Dialog classes={{ paper: classes.overflowVisible }} open={open} maxWidth="md" {...baseDialogProps}>
      <DialogTitle>{markingPaid ? 'Mark as Paid' : defaultTitle}</DialogTitle>
      <DialogContent className={classes.overflowVisible}>
        <div className={classes.root}>
          {(!isRequest || markingPaid) && (
            <DropdownSelect
              variant="single"
              value={typeValue}
              label={'Type/Method'}
              fullWidth={true}
              name={'type'}
              onChange={handleTypeChange}
              options={typeOptions}
              searchable
              placeholder={{
                selectPlaceholder: 'Select a Payment Method',
                plural: 'Payment Methods'
              }}
              disabled={readonlyFields}
              popperModifiers={{
                flip: {
                  enabled: false
                },
                preventOverflow: {
                  enabled: false
                }
              }}
              required
            />
          )}

          <Collapse show={validationError}>
            <Alert severity="error" noMargin>
              Please select a payment method
            </Alert>
          </Collapse>
          <KeypadInputField
            label={'Amount'}
            value={payment.amount}
            fullWidth={true}
            format={'price'}
            name={'amount'}
            onChange={handleKeypadChange}
            disabled={readonlyFields}
          />
          <SingleDatePicker
            label={isRequest && !markingPaid ? 'Requested on' : 'Payment Date'}
            date={dateValue}
            onChange={handleDateChange}
            disabled={readonlyFields}
          />

          <InputField
            label={'Note'}
            value={payment.note}
            multiline={true}
            maxRows={4}
            name={'note'}
            fullWidth={true}
            onChange={handleChange}
          />
          {!isRequest && (
            <FormControlLabel
              label={'This payment is a deposit'}
              disabled={readonlyFields}
              control={<Checkbox name={'deposit'} checked={payment.deposit} onChange={handleCheckboxChange} />}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions leftButton={leftButton}>
        <Button type="submit" variant={'contained'} onClick={handleConfirm} disabled={validationError}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )

  function handleChange(event: any) {
    const { value, name, type } = event.target
    setPayment({
      ...payment,
      [name]: type === 'number' ? Number(value) : value
    })
  }

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    const { name } = event.target

    setPayment({
      ...payment,
      [name]: !!checked
    })
  }

  function handleKeypadChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target

    setPayment({
      ...payment,
      [name]: Number(value)
    })
  }

  function handleDateChange(value: moment.Moment) {
    setPayment({
      ...payment,
      date: value?.unix() * 1000
    })
  }

  function handleTypeChange(value: any) {
    setPayment({
      ...payment,
      type: value?.value
    })
    setValidationError(false)
  }

  function handleConfirm() {
    if (!isRequest && !payment.type && payment.status === 'paid') {
      setValidationError(true)
    } else {
      const payments = getPayments({ quote })

      // != null is intentional, catches both null and undefined
      if (paymentIndex != null) {
        payments[paymentIndex] = {
          ...payment,
          amount: typeof payment.amount === 'number' ? payment.amount : parseFloat(payment.amount),
          status: markingPaid ? 'paid' : payment.status
        }
      } else {
        payments.push({
          ...payment,
          amount: typeof payment.amount === 'number' ? payment.amount : parseFloat(payment.amount)
        })
      }

      const updatedQuote = setPayments({ quote, payments, options })
      updateQuote({ quote: updatedQuote, autosave: true })

      if (onConfirm) {
        onConfirm(updatedQuote)
      }
    }
  }

  function handleCancel() {
    if (onCancel) {
      onCancel()
    }
  }
}

export default PaymentsDialog
